import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { ACESFilmicToneMapping, PCFShadowMap } from 'three'

/**
 * Loaders
 */
// Before GLTF: Draco Loader with Worker (don't forget to copy in static folder)
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')
//
const gltfLoader = new GLTFLoader()
// Combine with DRACO
gltfLoader.setDRACOLoader(dracoLoader)
//
const cubeTextureLoader = new THREE.CubeTextureLoader()


/**
 * Base
 */
// Debug
const gui = new dat.GUI()
//
// ! Constant to take all values for tweaking envMap Intensity on models materials
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Environment 
 */
//
// APPLY ENVIRONMENT TO MODELS/ Update Models
//
//  -> Provide Constant which will be instantiated after the model is been loaded
const updateAllMaterials = () =>
{
    scene.traverse((child) => 
    {    
// -> Only Update relevant materials (not all children)
      if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // -> THIS STAYS BECAUSE WE WANT TO TWEAK INTENSITY IN GUI
            // -> put envMapIntensity in debugObject and Update GUI with onChange
            child.material.envMapIntensity = debugObject.envMapIntensity   
            //
            // Add Shadows the long way -> bad for performance, good for understanding
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }    
    })
}
//
cubeTextureLoader.setPath('/textures/environmentMaps/4/')     
const environmentMap = cubeTextureLoader.load([
     'px.png', 
     'nx.png',
     'py.png', 
     'ny.png',
     'pz.png', 
     'nz.png'
 ])
 // Change encoding of renderer (see renderer)
 // Don't do it on normals, only on those textures user can see (GLTFLoader will set that automatical)
environmentMap.encoding = THREE.sRGBEncoding
// Must be after instantiating the scene
scene.background = environmentMap
// Short Version of APPLYING ENVMAP on Models
scene.environment = environmentMap
//
// Add to GUI 
// ->   gui.add(child.material, 'environmentIntensity', 0, 20, .001).name('Intensity envMap on Model')
//      doesn't work because we try to update several materials at once
debugObject.envMapIntensity = 2
gui.add(debugObject, 'envMapIntensity', 0, 10, .001).name('Intensity envMap on Model').onChange(updateAllMaterials)
  

/**
 * Models
 */
 gltfLoader.load(
    'models/ONE/R12_30_Handwork.glb',
    (gltf) =>
    {
       
        gltf.scene.scale.set(10,10,10)
        gltf.scene.position.set(2, -1, -2)
        gltf.scene.rotation.y = -Math.PI/1.4
        scene.add(gltf.scene)
        // Add to Gui
        const folderModel = gui.addFolder('Model')
        //    
        // folderModel.add(gltf.scene.rotation, 'x', -Math.PI , Math.PI ,.001).name('Rotate X')
        // folderModel.add(gltf.scene.rotation, 'y', -Math.PI , Math.PI ,.001).name('Rotate Y')
        // folderModel.add(gltf.scene.rotation, 'z', -Math.PI , Math.PI ,.001).name('Rotate Z')
        // folderModel.add(gltf.scene.position, 'x', -100 , 100 ,.001).name('Move on X')
        // folderModel.add(gltf.scene.position, 'y', -100 , 100 ,.001).name('Move on Y')
        // folderModel.add(gltf.scene.position, 'z', -100 , 100 ,.001).name('Move on Z')
        console.log(gltf)
        updateAllMaterials()
    }
)


/**
//  * Lights
//  */
// const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
// directionalLight.position.set(.25, 3, -2.25)
// // Activate Shadows (also renderer)
// directionalLight.castShadow = true
// // Use Camera helper to get best value for far/near/bottom etc.
// directionalLight.shadow.camera.far = 15
// // Change MapSize
// directionalLight.shadow.mapSize.set(1024, 1024)
// // PROBLEM BURGER: Shadow Acne 
// //      -> flat and round surfaces get strange waves of shadow
// //      -> same problem is solved by antialiasing
// //      -> where a pixel is half out/half in of geometry  and those inside will receive a shadow from the hamburger (=dark stripe flirring)
// //          even if they are on the outside
// //      Solution:   Shadow Bias will put edge on normals bottom to the inside 
// //                  (all pixel part in/out will be interpreted as outside and none receives a shadow)
// //      Use:        shadows bias or normals bias
// //      Here:       we use normals bias because we want to put normals slightly inside
// //      Flat surfaces:  use shadow bias
// //      Don't use to big values: Shadow of Objects become too small
// directionalLight.shadow.normalBias = .05
// scene.add(directionalLight)
// //
// // // Optimize shadow with camera helper
// // const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
// // scene.add(directionalLightCameraHelper)



// //
// // GUI for tweaking
// const folderLights = gui.addFolder('Light')
// folderLights.add(directionalLight, 'intensity', 0, 10, 0.001).name('Brightness')
// folderLights.add(directionalLight.position, 'x', -5, 5,.001).name('left/right')
// folderLights.add(directionalLight.position, 'y', -5, 5,.001).name('up/down')
// folderLights.add(directionalLight.position, 'z', -5, 5,.001).name('for/back')


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
   
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(4, 1, - 4)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    // ANTIALIASING 
    //      -> on edges of geometry for each pixel has to be decided, if that pixel is in or out of the geometry
    //         which makes the look at edges of geometry looking "pixelig" / "verpixelte Kanten" 
    //      Antialising must be changed here at instantiating the renderer
    // Screens with Pixelratio greater than 1 will not have that effect ->later lesson
    antialias: true 
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// 
// !!! Activate Physically correct lights
// -> this way lights from blender do the same here as in blender
renderer.physicallyCorrectLights = true
//
// Improve by using other encoding
// => Don't forget to set EnvMap to same encoding 
renderer.outputEncoding = THREE.sRGBEncoding
// Don't forget to set HDR to LDR Algorithme
//  -> HDR (High dynamic Range)/ Low DR LDR
//      -> LDR = 0 to 1
//      -> HDR can have higher values (example: white paper = 1/ sun = 2000)
//      -> There are several agorithms with different effect
//      -> Changing Algorithm will have effect even on non HDR models like FlightHelmet     
renderer.toneMapping = THREE.ACESFilmicToneMapping
//
// Add to GUI
// const folderToneMapping = gui.addFolder('Tone Mapping Algorithms')
// folderToneMapping.add(renderer, 'toneMapping', {
//     NO: THREE.NoToneMapping,
//     Linear: THREE.LinearToneMapping,
//     Reinhard: THREE.ReinhardToneMapping,
//     Cineon: THREE.CineonToneMapping,
//     ACESFilmic: THREE.ACESFilmicToneMapping
// })
// -> And tweak it :-)
renderer.toneMappingExposure = 3
//
// and change that in GUI to
// folderToneMapping.add(renderer, 'toneMappingExposure', 0, 10, .001).name('ToneMapping Exposure').onFinishChange(() =>
// {
//     renderer.toneMapping = Number(renderer.toneMapping)
//     updateAllMaterials()
// }
// )
//
// Add Shadows 
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

/**
 * Animate
 */
const tick = () =>
{
    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()